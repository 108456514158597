<template>
  <v-container class="">
    <v-row>
      <h1 class="font-semibold text-xl mb-2">Leaderboard</h1>
      <v-spacer></v-spacer>
      <div>
        <v-select
          v-model="selectedCountry"
          :items="['Nigeria', 'Ghana', 'Kenya']"
          label="Country"
        />
      </div>
    </v-row>
    <!-- <v-btn color="primary">Select Date</v-btn> -->

    <v-card :loading="loadingData">
      <v-tabs v-model="mainTab">
        <v-tab>Leaderboard</v-tab>
        <v-tab>Leaderboard History</v-tab>
      </v-tabs>
      <div class="mt-5">
        <v-tabs color="primary" center-active v-model="leaderboardTab">
          <v-tab>Giftcard</v-tab>
          <v-tab>Crypto Sales</v-tab>
          <!-- <v-tab>Crypto Purchase</v-tab> -->
        </v-tabs>
      </div>
    </v-card>

    <v-tabs-items v-model="mainTab">
      <v-tab-item>
        <div class="mt-5">
          <v-tabs v-model="selection">
            <v-tab>Day</v-tab>
            <v-tab>Week</v-tab>
            <v-tab>Month</v-tab>
          </v-tabs>
        </div>
        <div class="mt-5">
          <v-card class="">
            <div class="mt-5 grey lighten-5">
              <v-row>
                <v-col cols="12" v-for="(user, i) in users" :key="i">
                  <div
                    class="d-flex w-100 justify-space-between align-center px-5"
                  >
                    <div class="d-flex">
                      <div class="mr-5">
                        <v-avatar
                          :color="getColorBg()"
                          :size="$vuetify.breakpoint.smAndDown ? 35 : 65"
                          >{{ user.firstName | getInitials }}
                        </v-avatar>
                      </div>
                      <div>
                        <h4
                          class="text-capitalize font-weight-medium text-md-body-1 text-lg-h6"
                        >
                          {{ user.firstName || '' }}
                          {{ user.lastName || '' }}.
                        </h4>
                        <p
                          :title="user.username"
                          class="subtitle-2 text-truncate"
                        >
                          {{
                            user.username
                              | trucateUsername($vuetify.breakpoint.mdAndUp)
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="text-md-body-2 text-lg-h5">
                      {{ user.amount | formatAmount }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </v-tab-item>
      <v-tab-item>
        <v-card class="px-4 py-3">
          <v-row>
            <v-col>
              <v-text-field
                label="Start From"
                v-model="dates[0]"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="End At"
                v-model="dates[1]"
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="mt-5 grey lighten-5">
            <v-row>
              <v-col
                cols="12"
                v-for="(user, i) in previousLeaderboard"
                :key="i"
              >
                <div
                  class="d-flex w-100 justify-space-between align-center px-5"
                >
                  <div class="d-flex">
                    <div class="mr-5">
                      <v-avatar
                        :color="getColorBg()"
                        :size="$vuetify.breakpoint.smAndDown ? 35 : 65"
                        >{{ user.firstName | getInitials }}
                      </v-avatar>
                    </div>
                    <div>
                      <h4
                        class="text-capitalize font-weight-medium text-md-body-1 text-lg-h6"
                      >
                        {{ user.firstName || '' }}
                        {{ user.lastName || '' }}.
                      </h4>
                      <p
                        :title="user.username"
                        class="subtitle-2 text-truncate"
                      >
                        {{
                          user.username
                            | trucateUsername($vuetify.breakpoint.mdAndUp)
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="text-md-body-2 text-lg-h5">
                    {{ user.amount | formatAmount }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../../api/systemData'
import { numberWithCommas } from '../../utils/helpers'
import { getCurrencySymbol } from '../../utils/countryHelpers'

export default {
  name: 'Leaderboard',
  data() {
    return {
      getCurrencySymbol,
      numberWithCommas,
      leaderboardTab: 0,
      data: {},
      users: [],
      dates: [],
      selection: 0,
      loadingData: false,
      mainTab: null,
      previousLeaderboard: [],
      selectedCountry: 'Nigeria'
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user
    })
  },
  watch: {
    dates: function (newValue, oldValue) {
      // if (newValue !== oldValue) {
      this.getLeaderboardRange({
        category: this.leaderboardTab,
        period: this.selection
      })
      // }
    },
    selectedCountry: function (newValue, oldValue) {
      console.log('Change on selected country', newValue, oldValue)
      if (newValue !== oldValue) {
        this.getLeaderboard({
          category: this.leaderboardTab,
          period: this.selection
        })
      }
    },
    leaderboardTab: function (newValue, oldValue) {
      // 0 - Giftcard
      // 1 - Coin Sale
      // 2 - Coin Purchase
      if (oldValue !== newValue)
        this.getLeaderboard({
          period: this.selection,
          category: this.leaderboardTab
        })
      this.getLeaderboardRange({
        category: this.leaderboardTab
      })
    },
    selection: function (newValue, oldValue) {
      // 0 = Day
      // 1 - Week
      // 2 - Month
      // 3 -Year
      if (oldValue !== newValue)
        if (this.dates.length === 2) {
          this.getLeaderboardRange({
            category: this.leaderboardTab
          })
        } else {
          this.getLeaderboard({
            period: this.selection,
            category: this.leaderboardTab
          })
        }
    }
  },
  filters: {
    trucateUsername(value, breakpoint) {
      if (!value) return ''
      if (breakpoint) return value
      return value.length > 10
        ? `${value.toString().substring(0, 14)}...`
        : value
    },
    getInitials(value) {
      if (!value) return 'AA'
      return value[0]
    },
    formatAmount(value) {
      if (!value) return 0
      return Number.parseFloat(value).toFixed(2)
    }
  },
  created() {
    this.getLeaderboard({
      period: this.selection,
      category: this.leaderboardTab
    })
  },
  methods: {
    async getLeaderboardRange({ category }) {
      this.loadingData = true
      const categoryText = category === 0 ? 'giftcard' : 'coin'
      // const typeText = category === 0 ? 'sale' : 'purchase'
      const typeText = category === 0 || category === 1 ? 'sale' : 'purchase'
      const res = await dataApi.data().getLeaderboard({
        category: categoryText,
        type: typeText,
        requester: 'admin',
        from: this.dates[0],
        to: this.dates[1],
        country: this.selectedCountry
      })

      this.previousLeaderboard = res.data.data.leaderboard

      this.loadingData = false
    },
    async getLeaderboard({ period, category }) {
      this.loadingData = true
      const periodText =
        period === 0
          ? 'day'
          : period === 1
          ? 'week'
          : period === 2
          ? 'month'
          : 'day'
      const categoryText = category === 0 ? 'giftcard' : 'coin'
      // const typeText = category === 0 ? 'sale' : 'purchase'
      const typeText = category === 0 || category === 1 ? 'sale' : 'purchase'

      const res = await dataApi.data().getLeaderboard({
        period: this.dates.length > 0 ? undefined : periodText || 'day',
        category: categoryText,
        type: typeText,
        requester: 'admin',
        country: this.selectedCountry
      })

      this.users = res.data.data.leaderboard

      this.loadingData = false
    },
    getColorBg() {
      const colors = [
        'primary',
        'accent',
        'pink',
        'brown',
        'green',
        'purple',
        'yellow',
        'red',
        'indigo',
        'blue',
        'light-blue',
        'teal',
        'cyan',
        'green',
        'light-green',
        'lime',
        'amber',
        'orange',
        'blue-grey'
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    }
  }
}
</script>
